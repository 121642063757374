'use client';

import { getAuthToken, refreshToken } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { useQuery } from '@tanstack/react-query';
import { useMember } from 'hooks/useMember';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { LoadingSkeleton } from 'components';
import { UpgradeSubscription } from 'components/UpgradeSubscription';
import { usePathname, useRouter } from 'next/navigation';
import { onLogin } from 'services/auth';
import { identify, trackLoggedIn } from 'services/mixpanel';
import { routes } from 'services/routes';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (email, password) => void;
  logout: () => void;
  loading: boolean;
}

const AuthContext = createContext({});

const publicRoutes = [routes.login, routes.cookies, routes.forgotPassword];
const allowedPbRoutes = [routes.home, routes.account, routes.research.id];

export const AuthProvider = ({ children }) => {
  const [path, setPath] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(getAuthToken());
  const router = useRouter();
  const pathname = usePathname();
  const isProtectedRoute = !publicRoutes.find((route) => route === pathname);

  const refreshInterval = 4 * 60 * 1000;
  useQuery({
    queryKey: ['refresh-token'],
    queryFn: async () => {
      try {
        console.log('background refresh token');
        const response = await refreshToken();

        setToken(response.authToken);

        return response.authToken;
      } catch (error) {
        const userId = localStorage.getItem('userId');
        const databaseId = localStorage.getItem('databaseId');

        Bugsnag.notify(error, (event) => {
          event.context = 'background refresh token';
          event.addMetadata('User', {
            userId,
            databaseId,
          });
        });
        router.push(routes.login);
      }
    },
    refetchInterval: refreshInterval,
    refetchOnWindowFocus: true,
    enabled: isProtectedRoute,
  });

  useEffect(() => {
    if (pathname) {
      setPath(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(
    async (email, password) => {
      setLoading(true);
      try {
        const { userId } = await onLogin(email, password);

        identify(userId, { email });

        trackLoggedIn(email);

        if (path && !path.includes(routes.login)) {
          router.push(path);
        } else {
          router.push(routes.home);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path]
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token,
        login,
        loading,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext) as AuthContextType;

export const ProtectRoute = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const isPublicRoute = !!publicRoutes.find((route) => route === pathname);
  const isPbRoute =
    !!allowedPbRoutes.find((route) => route === pathname) ||
    pathname.includes(routes.portfolioBuilder.root);
  const { isAuthenticated, loading } = useAuth();
  const { isPbUser, memberships } = useMember();
  const isInactive = memberships && !memberships.length;

  useEffect(() => {
    if (!isPublicRoute && isInactive) {
      router.push(routes.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships]);

  // did this on purpose, can't remember why
  if (isPublicRoute) {
    return children;
  }

  if (loading || !isAuthenticated || !memberships) {
    return <LoadingSkeleton />;
  }

  if (isPbUser && !isPbRoute) {
    return <UpgradeSubscription />;
  }

  return children;
};
