'use client';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Chart, Commentary, Retrospective } from '@3fourteen/core';
import * as Popover from '@radix-ui/react-popover';
import { DocumentLoadEvent, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { RenderPrintProps } from '@react-pdf-viewer/print';
import { downloadFile } from 'helpers/downloadFile';
import { memo, ReactElement, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { AntIcon, FavoriteButton, SimpleLineIcon } from 'components';
import { trackDownloadedFile } from 'services/mixpanel';

interface PdfViewerProps {
  url: string;
  filename: string;
  defaultScale?: number;
  containerClass?: string;
  meta?: {
    post: Chart | Commentary | Retrospective;
  };
}

function PdfViewer({ url, filename, meta, defaultScale, containerClass }: PdfViewerProps) {
  const showExcerpt = meta?.post.categorySlug === 'charts';
  const showSearch =
    meta?.post.categorySlug === 'publications' ||
    meta?.post.categorySlug === 'model-updates' ||
    meta?.post.categorySlug === 'chart-books';

  const [numPages, setNumPages] = useState(0);
  const hasMultiplePages = numPages > 1;

  const onDocumentLoad = (e: DocumentLoadEvent) => {
    setNumPages(e.doc.numPages);
  };

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            {showSearch && (
              <div className='pl-2'>
                <ShowSearchPopover />
              </div>
            )}
            <div className='flex items-center flex-1'>
              <div className='pl-2 pr-0.5'>
                <ZoomOut />
              </div>
              <div className='px-0.5'>
                <Zoom />
              </div>
              <div className='px-0.5'>
                <ZoomIn />
              </div>
              {!isMobile && hasMultiplePages && (
                <div className='flex'>
                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px' }} className='flex items-center'>
                    <CurrentPageInput /> / <NumberOfPages />
                  </div>
                  <div className='px-1'>
                    <GoToNextPage />
                  </div>
                </div>
              )}
            </div>
            <div className='flex justify-center items-center flex-1'>
              {showExcerpt && (
                <Popover.Root>
                  <Popover.Trigger>
                    <div className='flex cursor-pointer'>
                      <SimpleLineIcon name='info' className='!text-lg !lg:text-xl mr-3' />
                    </div>
                  </Popover.Trigger>
                  <Popover.Content>
                    <p className='py-4 px-8 bg-white rounded max-w-[300px] lg:max-w-[400px] border border-solid border-black/10 shadow-lg'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: meta?.post?.excerpt,
                        }}
                      />
                    </p>
                  </Popover.Content>
                </Popover.Root>
              )}
              {meta?.post && (
                <div className='px-3'>
                  <FavoriteButton post={meta.post} />
                </div>
              )}
              <div className='px-3'>
                <button
                  onClick={() => {
                    trackDownloadedFile({ filename });
                    downloadFile(url, filename);
                  }}
                  style={{ border: 'none' }}>
                  <AntIcon name='download' className='!text-base lg:!text-lg' />
                </button>
              </div>
              <div className='px-3 flex items-center'>
                <Print>
                  {(props: RenderPrintProps) => (
                    <button onClick={props.onClick}>
                      <AntIcon name='printer' className='!text-lg lg:!text-xl' />
                    </button>
                  )}
                </Print>
              </div>
            </div>
            <div className='flex-1'></div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (tabs) => (hasMultiplePages ? [tabs[0]] : []),
  });

  return (
    <div style={{ height: isMobile ? 'auto' : '100vh' }} className={containerClass}>
      <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.3.122/pdf.worker.min.js'>
        <Viewer
          fileUrl={url}
          // @ts-ignore
          plugins={[defaultLayoutPluginInstance]}
          defaultScale={defaultScale}
          onDocumentLoad={onDocumentLoad}
        />
      </Worker>
    </div>
  );
}

export default memo(PdfViewer);
