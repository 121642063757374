'use client';

import { getCloudfrontSignedUrl } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { useEffect, useState } from 'react';

export function useSignedCloudfrontUrl(key = '') {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function getUrl() {
      setIsLoading(true);

      try {
        const signedUrl = await getCloudfrontSignedUrl({
          key,
        });

        setUrl(signedUrl);
      } catch (error) {
        setError(error?.message || 'An unknown error occurred.');

        Bugsnag.notify(error, (event) => {
          event.context = 'useSignedCloudfrontUrl()';
          event.addMetadata('Key', {
            key,
          });
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (!!key) {
      getUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return {
    isLoading,
    url,
    error,
  };
}
