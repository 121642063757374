'use client';

import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage, useScroll } from 'react-use';

import { AntIcon, FAB, VideoPlayer } from 'components';

const transition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.2,
};

const widePosition = {
  bottom: 16,
  left: 250,
};

const desktopPosition = {
  bottom: 16,
  left: 80,
};

const tabletPosition = {
  top: 12,
  left: 12,
};

const extraWidePopup = {
  position: 'fixed',
  maxWidth: [330, 360],
  transition,
  ...widePosition,
};

const desktopPopup = {
  position: 'fixed',
  maxWidth: [330, 360],
  transition,
  ...desktopPosition,
};

const tabletPopup = {
  position: 'fixed',
  maxWidth: [290, 300],
  transition,
  ...tabletPosition,
};

interface VideoPopupProps {
  src: HTMLVideoElement['src'];
  currentTime: HTMLVideoElement['currentTime'];
  poster: HTMLVideoElement['poster'];
  scrollRef: RefObject<HTMLDivElement>;
  chaptersSrc: HTMLTrackElement['src'];
}

function VideoPopup({ src, currentTime, poster, scrollRef, chaptersSrc }: VideoPopupProps) {
  const isSmallScreen = useMediaQuery(768);
  const isMediumScreen = useMediaQuery(1024);
  const isDesktop = useMediaQuery(1280);
  const { y } = useScroll(scrollRef);
  const controls = useAnimation();
  const [isPopup, setIsPopup] = useState(false);
  const [aboveTheFold, setAboveTheFold] = useState(true);
  const [popupPref, setPopupPref] = useLocalStorage('video-popup', 'true');
  const [muted, setMuted] = useState(false);

  const videoVariants = useMemo(() => {
    let animatedStyles: object = extraWidePopup;

    if (isDesktop) {
      animatedStyles = desktopPopup;
    }

    if (isMediumScreen) {
      animatedStyles = tabletPopup;
    }

    return {
      initial: {
        position: 'static',
        maxWidth: [770, 800],
        transition: {
          type: 'tween',
          ease: 'easeIn',
          duration: 0.25,
          delay: 0.1,
        },
      },
      animate: animatedStyles,
    };
  }, [isMediumScreen, isDesktop]);

  useEffect(() => {
    if (!isSmallScreen) {
      if (y > 600 && !isPopup) {
        setAboveTheFold(false);

        if (popupPref === 'true') {
          controls.start('animate');
          setIsPopup(true);
        }
      }
      if (y < 600 && isPopup) {
        setAboveTheFold(true);

        if (popupPref === 'true') {
          controls.start('initial');
          setIsPopup(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [y, isSmallScreen, popupPref]);

  const onClick = useCallback(() => {
    controls.start('initial');
    setIsPopup(false);
    setPopupPref('false');
    setMuted(true);
  }, [controls, setPopupPref]);

  const showPopupVideo = useCallback(() => {
    controls.start('animate');
    setIsPopup(true);
    setPopupPref('true');
  }, [controls, setPopupPref]);

  return (
    <div ref={scrollRef}>
      <div className='md:h-[450px]'>
        <motion.div
          className='relative flex justify-center items-center mx-auto max-w-[800px]'
          layout
          animate={controls}
          // @ts-ignore
          variants={videoVariants}>
          <div className='w-full'>
            {isPopup && (
              <button className='absolute top-0 right-0 z-[2]' onClick={onClick}>
                <div className='p-1 rounded bg-black/40'>
                  <AntIcon name='close' color='white' />
                </div>
              </button>
            )}
            {!!src && (
              <VideoPlayer
                src={src}
                poster={poster}
                muted={muted}
                currentTime={currentTime}
                chaptersSrc={chaptersSrc}
              />
            )}
          </div>
        </motion.div>
      </div>
      {popupPref === 'false' && !aboveTheFold && (
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <FAB icon='laptop' shape='circle' onClick={showPopupVideo} />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default memo(VideoPopup);
