'use client';

import { classNames } from 'helpers/classNames';
import { FormEvent, memo, useCallback, useEffect, useState } from 'react';
import { HTMLMediaControls, HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook';

import { useSearchParams } from 'next/navigation';

export interface VideoChapter extends TextTrackCue {
  text: string;
}

interface SeekBarProps {
  time: HTMLMediaState['time'];
  seek: HTMLMediaControls['seek'];
  duration: HTMLMediaState['duration'];
  chapters: VideoChapter[];
  isFullScreen: boolean;
}

const SeekBar = ({ time, duration, chapters, seek, isFullScreen }: SeekBarProps) => {
  const searchParams = useSearchParams();
  const timestampQuery = searchParams?.get('ts');
  const [seekValue, setSeekValue] = useState(time / duration);

  useEffect(() => {
    if (timestampQuery) {
      const timestamp = parseFloat(timestampQuery as string);

      seek(timestamp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSeekValue(time / duration);
  }, [time, duration]);

  const onChapterPress = useCallback(
    (startTime: number) => {
      setSeekValue(startTime / duration);
      seek(startTime);
    },
    [duration, seek]
  );

  return (
    <div className='relative flex items-center'>
      <input
        key='seekbar'
        id='seekbar'
        type='range'
        width='100%'
        min={0}
        max={1}
        step={0.001}
        value={seekValue}
        onChange={(event: FormEvent<HTMLInputElement>) => {
          setSeekValue((event.target as HTMLFormElement).value);
          seek((event.target as HTMLFormElement).value * duration);
        }}
      />

      {chapters.map((chapter) => {
        const left = (chapter.startTime / duration) * 100;
        return (
          <button
            className='absolute top-0 cursor-pointer'
            onClick={() => onChapterPress(chapter.startTime)}
            key={`${chapter.startTime}`}
            style={{
              left: `${left}%`,
            }}>
            <div className='bg-teal-300 h-2 w-0.5 rounded-full' />
            <div
              className={classNames(
                'hidden md:block text-white uppercase font-semibold mt-0.5 cursor-pointer',
                isFullScreen ? 'text-sm' : 'text-xs'
              )}>
              {chapter.id}
            </div>
          </button>
        );
      })}
    </div>
  );
};

SeekBar.displayName = 'SeekBar';

export default memo(SeekBar);
