import { AnchorHTMLAttributes, memo } from 'react';

import { AntIcon } from 'components';

interface DownloadButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  text?: string;
  iconColor?: string;
  iconSize?: number;
}

const DownloadButton = ({
  text = 'Download',
  iconColor = '#254653',
  iconSize = 16,
  ...rest
}: DownloadButtonProps) => {
  return (
    <a {...rest} className='btn btn-secondary btn-xs'>
      <AntIcon name='download' color={iconColor} size={iconSize} />
      <span className='hidden md:inline-block ml-1.5 text-teal-800'>{text}</span>
    </a>
  );
};

export default memo(DownloadButton);
