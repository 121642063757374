import { authorMap, Commentary } from '@3fourteen/core';
import dayjs from 'dayjs';
import parse, { Element } from 'html-react-parser';
import { memo, useMemo } from 'react';

import { ImageHtml } from 'components';
import Image from 'next/legacy/image';

interface CommentaryRendererProps {
  post: Commentary;
}

function CommentaryRenderer({ post }: CommentaryRendererProps) {
  const { content, authorId, author, date, categorySlug, filename } = post;

  const authorDisplay = !!authorMap[authorId]?.credentials
    ? `${author}, ${authorMap[authorId].credentials}`
    : author;

  const options = useMemo(() => {
    return {
      replace: (domNode) => {
        const { attribs } = domNode;

        if (
          domNode instanceof Element &&
          attribs?.src &&
          attribs?.src.includes('3fourteen-reports')
        ) {
          return (
            <ImageHtml
              src={attribs.src}
              date={date}
              categorySlug={categorySlug}
              className={attribs?.class}
              {...attribs}
            />
          );
        }
      },
    };
  }, [categorySlug, date]);

  return (
    <div className='bg-zinc-100 lg:p-6'>
      <div className='max-w-[980px] mx-auto'>
        <header className='bg-teal-800 my-0 flex items-center justify-between py-2 px-4 md:py-5 md:px-10  text-white'>
          <div className='hidden md:block'>
            <Image
              src='/images/logos/logo-slogan-white.svg'
              alt='3Fourteen Research Logo'
              width={200}
              height={56}
            />
          </div>
          <div className='md:hidden'>
            <Image
              src='/images/logos/mark-white.svg'
              alt='3Fourteen Research Logo'
              width={50}
              height={50}
            />
          </div>
          <div className='h-full flex flex-col items-end justify-between'>
            <p>
              <span className='font-medium font-serif'>{`${authorDisplay}`}</span>
              <span className='font-serif font-light inline-block ml-2'>
                {authorMap[authorId].title}
              </span>
            </p>
            <p className='underline text-sm md:text-base'>
              <a href={`https://twitter.com/@${authorMap[authorId].twitter}`} target='blank'>
                {`@${authorMap[authorId].twitter}`}
              </a>
            </p>
            <p className='uppercase text-13 mt-1 md:mt-1.5'>{dayjs(date).format('MMMM D, YYYY')}</p>
          </div>
        </header>
        {parse(content, options)}
      </div>
    </div>
  );
}

export default memo(CommentaryRenderer);
