import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo } from 'react';

import { DownloadButton } from 'components';
import Link from 'next/link';

interface SupportingChartLinksProps {
  url: string;
}

const SupportingChartLinks = ({ url }: SupportingChartLinksProps) => {
  const urlComponents = url.split('/');
  const filename = [...urlComponents].pop();
  const chartUrl = [...urlComponents].slice(0, -1).join('/');
  const { url: signedUrl } = useSignedCloudfrontUrl(filename);

  return (
    <div className='flex items-center justify-end'>
      {signedUrl && <DownloadButton href={signedUrl} download={filename} />}
      <Link href={chartUrl} className='ml-5 font-semibold text-sky-700 flex items-center'>
        <span className='text-15 inline-block mr-1'>PDF</span>
        <ArrowTopRightOnSquareIcon className='w-4 h-4' />
      </Link>
    </div>
  );
};

export default memo(SupportingChartLinks);
