'use client';

import { MyPortfolio, PortfolioConfig } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import * as Yup from 'yup';

import { AlphaModelSelect } from 'components/AlphaModelSelect';
import { AssetsMenuModal } from 'components/AssetsMenuModal';
import { BenchmarkSelect } from 'components/BenchmarkSelect';
import { Button } from 'components/Button';
import { DateTimeDisplay } from 'components/DateTimeDisplay';
import { ModelNameField } from 'components/ModelNameField';
import { PageHeader } from 'components/PageHeader';
import { PbOutputViewer } from 'components/PbOutputViewer';
import { PbTips } from 'components/PbTips';
import { PortfolioBuilderLoadingState } from 'components/PortfolioBuilderLoadingState';
import { RebalanceConfigSelect } from 'components/RebalanceConfigSelect';
import { SimpleLineIcon } from 'components/SimpleLineIcon';
import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';
import { trackRanPortfolio } from 'services/mixpanel';
import { routes } from 'services/routes';

import { checkIfPortfolioGenerated } from './actions';

const PortfolioBuilderSchema = Yup.object()
  .shape({
    model_name: Yup.string().default('').required('Your model needs a name'),
    alpha_model: Yup.object().shape({
      id: Yup.string().required().default(''),
      name: Yup.string().required().default(''),
    }),
    benchmark_series_id: Yup.string().required().default(''),
    rebalance_config: Yup.string().required().default('monthly'),
    assets: Yup.array()
      .of(
        Yup.object().shape({
          series_id: Yup.string(),
          start_date: Yup.string(),
          bench_weight: Yup.number(),
          max_weight: Yup.number().test(
            'max-weight',
            'Max weight must be greater than or equal to min weight',
            (value, ctx) => {
              return value >= ctx.parent.min_weight;
            }
          ),
          min_weight: Yup.number().test(
            'min-weight',
            'Min weight must be less than or equal to max weight',
            (value, ctx) => {
              return value <= ctx.parent.max_weight;
            }
          ),
        })
      )
      .required('Select some assets')
      .default([])
      .test('assets', 'You forgot to pick your assets!', (arr) => !!arr.length),
  })
  .required();

interface PortfolioBuilderProps {
  portfolio?: MyPortfolio;
}

function PortfolioBuilder({ portfolio }: PortfolioBuilderProps) {
  const queryClient = useQueryClient();
  const timerRef = useRef<ReturnType<typeof setTimeout>>(null);
  const formRef = useRef<FormikProps<FormikValues>>();
  const router = useRouter();

  const params = useParams();

  const isExistingPortfolio = !!params.id;

  const [error, setError] = useState('');
  const [selectedPortfolio, setSelectedPortfolio] = useState(portfolio);
  const [config, setConfig] = useState<PortfolioConfig>(undefined);
  const [shouldConnect, setShouldConnect] = useState(false);
  const [initialValues, setInitialValues] = useState(PortfolioBuilderSchema.cast());

  const refetchPortfolio = useCallback(async () => {
    if (portfolio) {
      try {
        await queryClient.refetchQueries({
          queryKey: ['pb-portfolio', portfolio.id],
          type: 'active',
          exact: true,
        });
      } catch (error) {
        Bugsnag.notify(error, (event) => {
          event.context = 'refetchQueries in PB';
          event.addMetadata('Meta', {
            userId: localStorage.getItem('databaseId'),
            config: {
              ...formRef.current.values,
            },
          });
        });
      }
    }
  }, [queryClient, portfolio]);

  useEffect(() => {
    if (portfolio) {
      setSelectedPortfolio(portfolio);
      setConfig(portfolio.config);
      setInitialValues(portfolio.config);
    }
  }, [portfolio]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    process.env.NEXT_PUBLIC_WEBSOCKET_URL,
    {
      onOpen: () => {
        if (config) {
          sendMessage(
            JSON.stringify({
              action: 'queue',
              data: {
                userId: localStorage.getItem('databaseId'),
                config,
              },
            })
          );

          setConfig(undefined);
        }
      },
      onError: (e) => {
        Bugsnag.notify(e['error'], (event) => {
          event.context = 'onError() in useWebSocket()';
          event.addMetadata('Meta', {
            userId: localStorage.getItem('databaseId'),
            config,
          });
        });
      },
    },
    shouldConnect
  );

  const onSuccess = useCallback(
    async (filename: string) => {
      // check that the url doesn't have the :id param
      if (!params.id) {
        const hash = filename.split('/')[1];

        router.push(`/portfolio-builder/portfolios/${hash}`);
      } else {
        refetchPortfolio();
      }
    },
    // don't run on router dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetchPortfolio]
  );

  useEffect(() => {
    statusRef.current = readyState;
  }, [readyState]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const isViewingPortfolio = !!isExistingPortfolio && readyState !== ReadyState.OPEN && !error;

  const statusRef = useRef(readyState);
  const onSubmit = useCallback(
    (values) => {
      trackRanPortfolio();

      // While this doesn't look like much, connecting to the socket sends the config to the server
      setShouldConnect(true);

      // clear previous error & pdf
      setError('');

      console.log(values);

      setConfig(values);

      timerRef.current = setTimeout(async () => {
        if (statusRef.current === ReadyState.OPEN) {
          const generatedPortfolio = await checkIfPortfolioGenerated(
            formRef.current?.values.model_name
          );

          if (!!generatedPortfolio) {
            onSuccess(`${generatedPortfolio?.user_id}/${generatedPortfolio?.id}/output.pdf`);

            Bugsnag.notify(new Error('Builder timed out but portfolio generated'), (event) => {
              event.context = 'onSubmit in PortfolioBuilder';
              event.addMetadata('Meta', {
                userId: localStorage.getItem('databaseId'),
                config: {
                  ...formRef.current.values,
                },
              });
            });
          } else {
            setError('timeout');

            Bugsnag.notify(
              new Error('Websocket timed out waiting for response from server'),
              (event) => {
                event.context = 'onSubmit in PortfolioBuilder';
                event.addMetadata('Meta', {
                  userId: localStorage.getItem('databaseId'),
                  testing: 'true',
                  config: {
                    ...formRef.current.values,
                  },
                });
              }
            );
          }

          setShouldConnect(false);
        }
      }, 120000);
    },
    [onSuccess]
  );

  useEffect(() => {
    if (!!lastMessage && readyState === ReadyState.OPEN) {
      const data = JSON.parse(lastMessage.data);
      const { filename, error } = data;
      clearTimeout(timerRef.current);

      if (!!error?.message || !filename) {
        setError(error.message);

        Bugsnag.notify(new Error(error.stacktrace), (event) => {
          event.context = 'Response posted back to websocket';
          event.addMetadata('Meta', {
            userId: localStorage.getItem('databaseId'),
            config: {
              ...formRef.current.values,
            },
          });
        });
      } else {
        onSuccess(filename);
      }

      setShouldConnect(false);
    }
  }, [lastMessage, readyState, onSuccess]);

  const goToBuilder = useCallback(() => {
    router.push(routes.portfolioBuilder.root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('The websocket is currently: ', connectionStatus);
  }, [connectionStatus]);

  return (
    <div className='h-full flex flex-col'>
      <PageHeader
        leftEl={
          <div className='mr-2'>
            <SimpleLineIcon name='wrench' size={20} />
          </div>
        }
        className='!mb-0'
        heading={
          <div className='flex items-center'>
            <h1 className='flex-1 text-xl md:text-2xl text-center md:text-left font-semibold mr-4'>
              Portfolio Builder
            </h1>
          </div>
        }
        rightEl={
          portfolio && (
            <Button className='btn btn-primary p-0 h-9 w-9 rounded-full' onClick={goToBuilder}>
              <PlusIcon className='h-6 w-6 text-white' />
            </Button>
          )
        }
      />

      <div className='flex flex-1'>
        <div className='flex flex-col justify-between overflow-y-auto border-r border-zinc-200 border-solid'>
          <Formik
            innerRef={formRef}
            key='pb-form'
            validationSchema={PortfolioBuilderSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {(formik) => {
              return (
                <Form className='flex flex-1 pt-6 '>
                  <div className='px-4 w-72 md:px-6'>
                    <div>
                      <label
                        htmlFor='model_name'
                        className='block text-sm font-medium text-gray-700 uppercase'>
                        Name
                      </label>
                      <ModelNameField />
                    </div>
                    <div className='my-6'>
                      <Field name='alpha_model' component={AlphaModelSelect} />
                    </div>
                    <Field name='benchmark_series_id' component={BenchmarkSelect} />
                    <div className='mt-6'>
                      <Field name='rebalance_config' component={RebalanceConfigSelect} />
                    </div>
                    <div className='mt-6 mb-10 relative'>
                      <Field name='assets' component={AssetsMenuModal} />
                      <div className='absolute bottom-[-18px] left-0 text-xs text-red-800 font-semibold'>
                        <ErrorMessage name='assets' />
                      </div>
                    </div>
                    <Button
                      type='submit'
                      disabled={
                        !formik.isValid ||
                        readyState === ReadyState.OPEN ||
                        readyState === ReadyState.CONNECTING
                      }
                      className='btn-primary w-2/3 mx-auto'>
                      {readyState === ReadyState.OPEN || readyState === ReadyState.CONNECTING ? (
                        <ArrowPathIcon className='text-white h-5 w-5 animate-spin-slow' />
                      ) : (
                        <div className='flex items-center'>
                          <span>Run</span>
                          <ArrowPathIcon className='text-white h-5 w-5 ml-2' />
                        </div>
                      )}
                    </Button>
                    {selectedPortfolio?.last_modified && (
                      <div className='flex items-center mt-8 justify-center'>
                        <span className='font-bold mr-2 uppercase text-sm'>Last run: </span>
                        <DateTimeDisplay date={selectedPortfolio.last_modified} />
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>

          <div className='pb-6 flex flex-col'>
            <Link
              target='blank'
              href={routes.portfolioBuilder.appendix}
              className='pl-6 font-semibold border-y border-solid border-gray-200 py-2 text-sky-700'>
              Appendix
            </Link>
            <Link
              href={routes.portfolioBuilder.demo}
              className='pl-6 font-semibold border-b border-solid border-gray-200 py-2 text-sky-700'>
              Quick Tips & Demo
            </Link>
          </div>
        </div>
        <div className='flex-1 h-[calc(100vh_-_80px)]'>
          {!isViewingPortfolio && readyState === ReadyState.UNINSTANTIATED && !error && (
            <div className='h-full flex items-center justify-center 3xl:items-start 3xl:mt-20'>
              <div className='max-w-screen-md pr-16 pl-10 pb-6 pt-10 border border-solid border-gray-200 rounded-xl'>
                <PbTips />
                <div className='flex justify-center'>
                  <Link
                    href={routes.portfolioBuilder.demo}
                    className='btn btn-secondary btn-sm w-[200px]'>
                    Watch Demo
                  </Link>
                </div>
              </div>
            </div>
          )}

          {readyState === ReadyState.OPEN && (
            <PortfolioBuilderLoadingState numOfAssets={formRef?.current.values.assets.length} />
          )}

          {error && (
            <div className='flex-1 h-full flex flex-col justify-center items-center'>
              <p className='text-2xl mb-4'>Something happened.</p>
              {error === 'timeout' ? (
                <div className='max-w-md text-center'>
                  Looks like the request timed out. This could mean there was a disruption in
                  connection—not a failed portfolio generation. To confirm this, check out
                  <Link
                    href={routes.portfolioBuilder.portfolios.root}
                    className='mx-1 font-semibold text-sky-700 underline'
                    target='blank'>
                    My Portfolios
                  </Link>
                  and see if your portfolio is there. Otherwise, please try again.
                </div>
              ) : (
                <p>Please try again or check back later.</p>
              )}
            </div>
          )}

          {isViewingPortfolio && (
            <div className='w-full pb-tabs'>
              <Tabs forceRenderTabPanel={true}>
                <TabList style={{ marginBottom: 0 }}>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>Overview</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>Weight Summary</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>Latest Weights</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>MTD Attribution</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>Recent Performance</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>
                        Historical Weights Overview
                      </span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>Historical Weights</span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>
                        Calendar Year Performance
                      </span>
                    </div>
                  </Tab>
                  <Tab>
                    <div className='flex items-center justify-center h-full min-h-[48px]'>
                      <span className='text-sm leading-tight text-center'>Bundle</span>
                    </div>
                  </Tab>
                </TabList>
                <TabPanel>
                  <PbOutputViewer filename='overview' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='current_weights' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='latest_weights' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='attribution_monthly' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='recent_perf' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='weight_history_overview' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='weight_history' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='calendar_perf' />
                </TabPanel>
                <TabPanel>
                  <PbOutputViewer filename='output' />
                </TabPanel>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

PortfolioBuilder.displayName = 'PortfolioBuilder';

export default memo(PortfolioBuilder);
