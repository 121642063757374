import {
  getMe,
  GetMeResponse,
  PORTFOLIO_BUILDER_ANNUAL_MEMBERSHIP_ID,
  PORTFOLIO_BUILDER_MONTHLY_MEMBERSHIP_ID,
} from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from 'contexts/auth';

export function useMember() {
  const { isAuthenticated } = useAuth();
  const { data, ...rest } = useQuery<GetMeResponse>({
    queryKey: ['member'],
    queryFn: getMe,
    enabled: isAuthenticated,
  });

  return {
    isPbUser: !!data?.member.active_memberships.find(
      ({ id }) =>
        id === PORTFOLIO_BUILDER_MONTHLY_MEMBERSHIP_ID ||
        id === PORTFOLIO_BUILDER_ANNUAL_MEMBERSHIP_ID
    ),
    memberships: data?.member.active_memberships,
    subscriptions: data?.member.recent_subscriptions,
    transactions: data?.member.recent_transactions,
    member: data?.member,
    ...rest,
  };
}
