import { classNames } from 'helpers/classNames';
import { formatTime } from 'helpers/formatTime';
import { forwardRef, memo, RefObject } from 'react';
import { isMobile } from 'react-device-detect';
import { HTMLMediaControls, HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook';

import {
  AntIcon,
  PlaybackControls,
  SeekBar,
  SimpleLineIcon,
  VideoChaptersMenu,
  VolumeControls,
} from 'components';
import { VideoChapter } from 'components/SeekBar/component';

interface Controls extends HTMLMediaControls {
  toggleFullscreen: () => void;
}

interface State extends HTMLMediaState {
  isFullscreen: boolean;
}

interface VideoControlBarProps {
  controls: Controls;
  state: State;
  chapters: VideoChapter[];
}

const VideoControlBar = forwardRef(
  ({ controls, state, chapters }: VideoControlBarProps, ref: RefObject<HTMLVideoElement>) => {
    const { isFullscreen, time, duration, paused, volume, muted } = state;
    const { toggleFullscreen, play, pause, mute, unmute, seek } = controls;

    const iconSize = 18;

    const onChapterPress = (startTime: number) => {
      seek(startTime);
    };

    if (!duration || !chapters) return null;

    return (
      <div
        className='w-full bg-black flex flex-col justify-between pl-3 pr-4 rounded-bl rounded-br'
        data-testid='video-controls'>
        <div className='w-full flex items-center justify-between pt-2 pb-1 leading-none'>
          <div className='flex items-center'>
            <div className={`flex items-center ${isFullscreen ? 'w-10 h-7' : 'w-8 h-5'}`}>
              {paused ? (
                <button onClick={play} data-testid='play-btn'>
                  <AntIcon name='caretright' color='white' size={iconSize} />
                </button>
              ) : (
                <button onClick={pause} data-testid='pause-btn'>
                  <AntIcon name='pause' color='white' size={iconSize + 2} />
                </button>
              )}
            </div>
            <p className={`text-white ${isFullscreen ? 'text-base' : 'text-sm'}`}>
              {`${formatTime(time)} / ${formatTime(duration)}`}
            </p>
          </div>

          <div className='flex items-center h-full'>
            <VolumeControls
              ref={ref}
              volume={volume}
              isFullscreen={isFullscreen}
              muted={muted}
              mute={mute}
              unmute={unmute}
            />
            {!!chapters.length && (
              <div className='ml-6'>
                <VideoChaptersMenu
                  chapters={chapters}
                  onClick={onChapterPress}
                  isFullscreen={isFullscreen}
                />
              </div>
            )}
            <PlaybackControls ref={ref} isFullscreen={isFullscreen} />
            {!isMobile && (
              <>
                {isFullscreen ? (
                  <button onClick={toggleFullscreen} className='font-semibold'>
                    <SimpleLineIcon name='size-actual' color='white' size={iconSize} />
                  </button>
                ) : (
                  <button onClick={toggleFullscreen} className='font-semibold'>
                    <SimpleLineIcon name='frame' color='white' size={iconSize} />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={classNames(
            !!chapters.length ? 'md:pb-6' : '',
            'w-full relative z-[4] bg-black flex-grow pb-2'
          )}>
          <SeekBar
            time={time}
            duration={duration}
            chapters={chapters}
            seek={seek}
            isFullScreen={isFullscreen}
          />
        </div>
      </div>
    );
  }
);

VideoControlBar.displayName = 'VideoControlBar';

export default memo(VideoControlBar);
