export function handleUrlParams(obj: any) {
  const url = new URL(location?.href);

  Object.keys(obj).forEach((key) => {
    const value: [] | string = obj[key];
    let params;

    if (Array.isArray(value)) {
      params = value.join(',');
    } else if (value !== undefined && value !== null) {
      params = `${value}`;
    }

    if (!!params) {
      url.searchParams.set(key, params);
    } else {
      url.searchParams.delete(key);
    }
  });

  return url;
}
